@media only screen and ( max-width: 500px ) {
    .branding {
        @include layout( mobile );
    
        > article {
            grid-column: 1 / span 4;
            @include flex-wrap( nowrap, column, center, center );
            
            p {
                margin-top: -12px;
                width: 100%;
            }
        }
        .dropdown-container {
            grid-column: 1 / span 4;
            position: relative;
            @include flex-wrap( nowrap, column, center, center );
            gap: 24px;

            section {
                border-radius: 16px;
        
                h2 {
                    cursor: pointer;
                    margin: 0;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-left: 24px;
                    margin-bottom: 24px;
    
                    &:hover {
                        font-size: 32px;
                        height: 42px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }
                p {
                    padding-left: 24px;
                }
            }
            .drop {
                height: 80px;
            }
        }
    }
}