.gallery {
    @include flex-wrap( nowrap, column, center, center );
    width: 100%;
    gap: 24px;

    h1 {
        margin-top: 64px;
    }
    .photo-grid {
        @include layout( desktop );

        div {
            @include flex-wrap( nowrap, column, center, center );
            border-radius: 4px;
            text-align: center;
            font-family: 'Poppins';
            font-size: 64px;
            background-position: center;
            background-size: cover;
            background-color: green;
        }
    }
    .grid-top {
        grid-template-rows: repeat(3, 0.5fr);

        .a1 {
            grid-column: 1 / 5;
            grid-row: 1 / 3;
            aspect-ratio: 3 / 4;
        }
        .a2 {
            grid-column: 5 / 9;
            grid-row: 1 / 2;
        }
        .a3 {
            grid-column: 9 / 13;
            grid-row: 1 / 2;
        }
        .a4 {
            grid-column: 5 / 13;
            grid-row: 2 / 4;
        }
        .a5 {
            grid-column: 1 / 5;
            grid-row: 3 / 4;
            aspect-ratio: 3 / 2;
        }
    }
    .grid-mid {
        grid-template-rows: repeat(2, 0.5fr);

        .b1 {
            grid-column: 1 / 7;
            grid-row: 1 / 3;
            aspect-ratio: 1 / 1;
        }
        .b2 {
            grid-column: 7 / 10;
            grid-row: 1 / 2;
            aspect-ratio: 1 / 1;
        }
        .b3 {
            grid-column: 10 / 13;
            grid-row: 1 / 2;
            aspect-ratio: 1 / 1;
        }
        .b4 {
            grid-column: 7 / 10;
            grid-row: 2 / 3;
            aspect-ratio: 1 / 1;
        }
        .b5 {
            grid-column: 10 / 13;
            grid-row: 2 / 3;
            aspect-ratio: 1 / 1;
        }
    }
    .grid-bot {
        grid-template-rows: repeat(3, 0.5fr);

        .c1 {
            grid-column: 1 / 9;
            grid-row: 1 / 3;
            aspect-ratio: 3 / 2;
        }
        .c2 {
            grid-column: 9 / 13;
            grid-row: 1 / 2;
            aspect-ratio: 3 / 2;
        }
        .c3 {
            grid-column: 1 / 5;
            grid-row: 3 / 4;
        }
        .c4 {
            grid-column: 5 / 9;
            grid-row: 3 / 4;
        }
        .c5 {
            grid-column: 9 / 13;
            grid-row: 2 / 4;
            aspect-ratio: 3 / 4;
        }
    }
}