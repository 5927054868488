nav {
    @include flex-wrap( nowrap, row, center, center );
    width: 100%;
    height: 64px;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    overflow: hidden;
    z-index: 999;

    > div {
        @include layout( desktop );

        .nav-left {
            @include flex-wrap( nowrap, row, start, center );
            grid-column: 1 / 4;
            grid-row: 1;
            width: 100%;
            height: 64px;
        }
        .nav-mid {
            @include flex-wrap( nowrap, row, center, center );
            grid-column: 4 / 10;
            grid-row: 1;
            width: 100%;
            height: 64px;
            gap: 48px;

            .gallery, .about, .connect {
                transform: translateY(-100%);
                opacity: 0;
            }
            .gallery {
                animation: nav-down 1.5s 1.5s ease-in-out forwards;
            }
            .about {
                animation: nav-down 1.5s 1.7s ease-in-out forwards;
            }
            .connect {
                animation: nav-down 1.5s 1.9s ease-in-out forwards;
            }
        }
        .nav-right {
            @include flex-wrap( nowrap, row, end, center );
            grid-column: 10 / 13;
            grid-row: 1;
            width: 100%;
            height: 64px;
            gap: 16px;

            .instagram, .facebook, .linkedin {
                transform: translateY(100%);
                opacity: 0;
            }
            .instagram {
                animation: nav-up 1.5s 1.5s ease-in-out forwards;
            }
            .facebook {
                animation: nav-up 1.5s 1.7s ease-in-out forwards;
            }
            .linkedin {
                animation: nav-up 1.5s 1.9s ease-in-out forwards;
            }
        }
        .nav-burger {
            display: none;
        }
    }
}
@media only screen and ( max-width: 500px ) {
    nav {
        @include flex-wrap( nowrap, row, center, start );
        overflow: hidden;
        transition: 0.5s ease-in-out;
    
        > div {
            @include flex-wrap( nowrap, column, center, center );
            height: 60vh;
    
            .nav-left {
                @include flex-wrap( nowrap, row, start, center );
                position: absolute;
                top: 0;
                left: 16px;
                width: 64px;
                height: 64px;
            }
            .nav-mid {
                @include flex-wrap( nowrap, column, center, center );
                width: 100%;
                height: 64px;
                gap: 32px;

                .gallery, .about, .connect {
                    transform: translateY(0%);
                    opacity: 100%;
                    animation: none;
                }
            }
            .nav-right {
                @include flex-wrap( nowrap, row, center, center );
                width: 100%;
                height: 64px;
                margin-top: 32px;
                gap: 16px;

                .instagram, .facebook, .linkedin {
                    transform: translateY(0%);
                    opacity: 100%;
                    animation: none;
                }
            }
            .nav-burger {
                @include flex-wrap( nowrap, row, end, center );
                position: absolute;
                display: flex;
                top: 0;
                right: 16px;
                width: 64px;
                height: 64px;

                svg {
                    position: absolute;
                    margin-right: -8px;
                    width: 48px;
                    transition: 0.2s ease-in-out;
                }
            }
            .show {
                opacity: 100%;
            }
            .hide {
                opacity: 0;
            }
        }
    }
    .menu-open {
        height: 100vh;
        backdrop-filter: blur(8px);
    }
}
@keyframes nav-down {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 100%;
    }
}
@keyframes nav-up {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 100%;
    }
}