.branding {
    @include layout( desktop );

    > article {
        grid-column: 1 / span 12;
        @include flex-wrap( nowrap, column, center, center );

        h1 {
            margin-top: 80px;
        }
        p {
            margin-top: -12px;
            width: 75%;
        }
    }
    .dropdown-container {
        grid-column: 2 / 12;
        position: relative;
        @include flex-wrap( nowrap, column, center, center );
        gap: 24px;
        
        section {
            position: relative;
            cursor: pointer;
            width: 100%;
            background-color: #0a0a0a;
            height: var(--max-height);
            border-radius: 8px;
            border: 2px solid white;
            opacity: 0;
            overflow: hidden;
            transition: 0.2s ease-in-out;
            animation: ani-opacity 1s ease-in-out forwards;
    
            &:hover {
                border-color: $accent;
                
                h2 {
                    font-size: 34px;
                    height: 42px;
                    padding-top: 8.5px;
                    padding-bottom: 7.5px;
                }
                p {
                    color: $accent;
                    opacity: 100%;
                }
            }
            h2 {
                margin: 0;
                padding-top: 7px;
                padding-bottom: 9px;
                padding-left: 44px;
                margin-bottom: 22px;
                transition: 0.2s ease-in-out;
            }
            p {
                position: absolute;
                font-size: 14px;
                top: 32px;
                padding-left: 44px;
                opacity: 50%;
                margin-bottom: 2px;
                transition: 0.2s ease-in-out;
            }
            img {
                width: 100%;
    
                &.none {
                    display: none;
                }
            }
            &:nth-of-type( 3n + 1 ) {
                animation-delay: 0.5s;
            }
            &:nth-of-type( 3n + 2 ) {
                animation-delay: 0.8s;
            }
            &:nth-of-type( 3n + 3 ) {
                animation-delay: 1.1s;
            }
        }
        .drop {
            height: 80px;
        }
    }
}