.contact {
    @include layout( desktop );

    .img-wrap {
        @include flex-wrap( nowrap, row, center, start );
        width: 100%;
        height: 100vh;
        bottom: 320px;
        left: 0;
        position: absolute;
        z-index: -1;

        img {
            top: -10vh;
            max-height: 125vh;
            max-width: 100%;
            aspect-ratio: 3 / 2;
            position: absolute;
        }
        .img-front {
            animation: ani-mask-front 1s 1.5s steps(32) forwards;
            z-index: -2;
            mask: url("../assets/img/masks/inkmask-inv.png");
            mask-size: 3300% 100%;
            mask-position: 0 0;
        }
        .img-back {
            animation: ani-mask-right 1s 0.75s steps(32) forwards;
            z-index: -3;
            mask: url("../assets/img/masks/inkmask-right.png");
            mask-size: 100% 3300%;
            mask-position: 0 0;
            opacity: 50%;
        }
        .img-overlay {
            max-width: 100%;
            height: 125vh;
            aspect-ratio: 3 / 2;
            position: absolute;
            background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 75%, rgba(0,0,0,1) 100%);
            z-index: 1;
        }
    }
    .content {
        grid-column: 1 / span 12;
        @include flex-wrap( nowrap, column, center, center );

        > article {
            @include flex-wrap( nowrap, column, center, center );
            margin-top: 128px;

            h1 {
                opacity: 0;
                margin: 0;
                animation: contact-scale 2s 1.5s ease-in-out forwards;
            }
            > div {
                @include flex-wrap( nowrap, row, center, center );
                gap: 32px;
                margin-top: -16px;
                opacity: 0;
                animation: contact-scale 2s 1.7s ease-in-out forwards;

                h2 {
                    font-family: "Poppins";
                    font-size: 18px;
                }

                hr {
                    width: 4px;
                    height: 4px;
                    background: white;
                    border-radius: 8px;
                }
            }
        }
        > div {
            @include flex-wrap( nowrap, row, space-between, center );
            width: 64%;
            margin-top: 48px;

            .description {
                font-family: "Poppins";
                font-size: 14px;
                margin-top: 4px;
                color: rgba(220, 220, 220, 0.6);
                opacity: 0;
            }
            article:nth-child(1) {
                .button {
                    animation: contact-fade 4.2s ease-in-out;

                    > p {
                        animation: contact-fade 4.2s ease-in-out;
                    }
                }
                .description {
                    animation: ani-opacity 1s 2.8s ease-in-out forwards;
                }
            }
            article:nth-child(2) {
                .button {
                    animation: contact-fade 4s ease-in-out;

                    > p {
                        animation: contact-fade 4s ease-in-out;
                    }
                }
                .description {
                    animation: ani-opacity 1s 2.6s ease-in-out forwards;
                }
            }
            article:nth-child(3) {
                .button {
                    animation: contact-fade 4.4s ease-in-out;

                    > p {
                        animation: contact-fade 4.4s ease-in-out;
                    }
                }
                .description {
                    animation: ani-opacity 1s 3s ease-in-out forwards;
                }
            }
        }
    }
}

@keyframes ani-mask-front {
    0% {
        mask-position: 0 0;
    }
    100% {
        mask-position: 100% 0;
    }
}
@keyframes ani-mask-right {
    0% {
        mask-position: 0 0;
    }
    100% {
        mask-position: 0 100%;
    }
}
@keyframes contact-img-load {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes contact-scale {
    0% {
        opacity: 0;
        transform: scale(0.75);
    }
    100% {
        opacity: 100%;
        transform: scale(1);
    }
}
@keyframes contact-fade {
    0% {
        color: transparent;
        border-color: transparent;
        backdrop-filter: blur(0);
    }
    57% {
        color: transparent;
        border-color: transparent;
        backdrop-filter: blur(0);
    }
    100% {
        color: white;
        border-color: white;
        backdrop-filter: blur(8px);
    }
}