@media only screen and ( max-width: 500px ) {
    .home {
        @include layout( mobile );
        grid-template-rows: (112vh / 2);

        .img-wrap {
            width: 100%;
            height: 100vh;

            img {
                margin-top: -1px;
                max-height: 100vh;
                max-width: none;
            }
            .img-overlay {
                display: none;
            }
            .mobile-img-overlay {
                display: flex;
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,0) 100%);
                width: 100vw;
                height: 100vh;
                margin-top: 0;
                left: 0;
                position: absolute;
                z-index: 1;
            }
        }
        article {
            @include flex-wrap( nowrap, column, center, center );
            grid-column: 1 / 5;
            grid-row: 2;
            margin-top: 0vh;
            padding: 16px;
            height: auto;
            z-index: 1;
            background: rgba(0, 0, 0, 0.30);
            backdrop-filter: blur(12px);
            border-radius: 8px;
    
            h1 {
                font-size: 32px;
                line-height: 0.7;
                margin: 0;
                margin-bottom: 16px;
                margin-top: -24px;
        
                span {
                    font-size: 64px;
                }
            }
            > p {
                margin-top: 0;
                line-height: 1.25
                // display: none;
            }
            > div {
                @include flex-wrap( nowrap, row, start, center );
                width: 100%;
                gap: 16px;
            }
        }
    }
}