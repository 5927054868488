$primary: #ed1c24;
$secondary: #060e1a;
$accent: #1c62ed;
$transparent: rgba( 0, 0, 0, 0 );

// === UNITS ===
$xxs: 8px;
$xs: 12px;
$sm: 16px;
$pg: 18px; // Paragraph Desktop
$md: 24px;
$lg: 32px;
$xl: 48px;
$xxl: 64px;
$xxxl: 80px;
$mx: 96px;

// === PHOTOS ===
$pooch: url("../assets/img/gallery-photos/WaterproofBoots_Yellow_Detail_520x.png");