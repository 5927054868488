footer {
    @include flex-wrap( nowrap, column, center, center );
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(0,0,0,1) 64px);
    z-index: 999;

    section {
        @include flex-wrap( nowrap, column, center, center );
    }
    .footer-top {
        @include flex-wrap( nowrap, column, center, center );
        padding: 96px 0 64px 0;
        gap: 16px;

        .footer-icons {
            @include flex-wrap( nowrap, row, center, center );
            height: 64px;
            gap: 16px;
        }
        .footer-links {
            @include flex-wrap( nowrap, row, center, center );
            gap: 32px;

            hr {
                width: 2px;
                height: 2px;
                background: white;
                border-radius: 8px;
            }
            span {
                @include flex-wrap( nowrap, row, center, center );
                gap: 32px;
            }
        }
        .footer-links-mobile {
            display: none;
        }
    }
    .footer-bot {
        width: 100%;
        background: rgba(255, 255, 255, 0.08);

        p {
            font-size: 14px;
        }
    }
}

@media only screen and ( max-width: 500px ) {
    footer {
        @include flex-wrap( nowrap, column, center, center );
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        // background: linear-gradient(180deg, rgba(0,0,0,0) 0, rgba(0,0,0,1) 64px);
        background: transparent;
        z-index: 999;
    
        section {
            @include flex-wrap( nowrap, column, center, center );
        }
        .footer-top {
            @include flex-wrap( nowrap, column, center, center );
            padding: 48px 0 48px 0;
            gap: 16px;
    
            .footer-icons {
                @include flex-wrap( nowrap, row, center, center );
                height: 64px;
                gap: 16px;
            }
            .footer-links {
                @include flex-wrap( nowrap, row, center, start );
                gap: 32px;
    
                hr {
                    width: 2px;
                    height: 2px;
                    background: white;
                    border-radius: 8px;
                }
                span {
                    display: none;
                }
            }
            .footer-links-mobile {
                @include flex-wrap( nowrap, row, center, center );
                display: flex;
                gap: 32px;
            }
        }
        .footer-bot {
            width: 100%;
            background: rgba(255, 255, 255, 0.05);
    
            p {
                font-size: 14px;
            }
        }
    }
}