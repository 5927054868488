@font-face {
    font-family: 'StripesNation';
    src: url('../assets/fonts/StripesNation.ttf');
    font-weight: normal;
    font-style: normal;
}
* {
    color: white;
}

h1, h2, h3 {
    font-family: 'StripesNation';
    font-weight: normal;
}
h1 {
    font-size: 64px;
}
h2 {
    font-size: 36px;
    margin: 18px 0;
}
h3 {
    font-size: 24px;
}

p, a, button {
    font-size: 18px;
    font-family: 'Poppins';
}
p {
    line-height: 1.5;
}
a {
    @include flex-wrap( nowrap, row, center, center );
    text-decoration: none;
    position: relative;
    transition: 0.2s ease-in-out;

    .link-border {
        border: 2px solid white;
    }
    &.text-link::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-bottom: 2px solid rgba(white, 0.4);
        z-index: -1;
        scale: 0.4 0.9;
        transition: 0.2s ease-in-out;
    }
    &:hover {

        &::before {
            scale: 0.9 0.9;
            border-color: $primary;
        }
    }
}

@media only screen and ( max-width: 500px ) {
    h1 {
        font-size: 48px;
    }
    h2 {
        font-size: 32px;
        margin: 18px 0;
    }
    h3 {
        font-size: 24px;
    }
    p, a, button {
        font-size: 16px;
    }
}