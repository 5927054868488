.button {
    @include flex-wrap( nowrap, row, center, center );
    position: relative;
    padding: 4px 16px;
    border: 3px solid transparent;
    transition: 0.2s ease-in-out;

    p {
        @include flex-wrap( nowrap, row, center, center );
        margin: 0;
        transition: 0.2s ease-in-out;
        font-weight: 500;
    }
    .btn-bgr-wrap, .btn-bgl-wrap {
        position: absolute;
        overflow: hidden;
        width: 16px;
        height: 16px;
        opacity: 0;
    }
    .btn-bgr-wrap {
        top: 0;
        right: 0;
        transition: 0.2s ease-in-out;

        > div {
            top: 0;
            right: 0;
        }
    }
    .btn-bgl-wrap {
        bottom: 0;
        left: 0;
        transition: 0.2s ease-in-out;

        > div {
            bottom: 0;
            left: 0;
        }
    }
    .btn-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 3px solid white;
        transition: 0.2s ease-in-out;
        z-index: -1;
        scale: 1;
    }
    &:hover {
        p {
            scale: 0.95;
        }
        .btn-bgr-wrap, .btn-bgl-wrap {
            opacity: 100%;
        }
        .btn-bgr-wrap {
            top: 2px;
            right: 6px;
        }
        .btn-bgl-wrap {
            bottom: 2px;
            left: 6px;
        }
        &.primary {
            background: transparent;
            p {
                color: white;
            }
        }
        &.secondary {
            border-color: transparent;
        }
        &.tertiary {
            border-color: transparent;
            backdrop-filter: blur( 0px );
        }
    }

    &.primary {
        background: white;
        p {
            color: black;
        }
    }
    &.secondary {
        border-color: white;
    }
    &.tertiary {
        border-color: white;
        backdrop-filter: blur( 8px );
    }
}