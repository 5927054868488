@media only screen and ( max-width: 500px ) {
    .about {
        @include flex-wrap( nowrap, column, center, center );
        width: 100%;
        padding: 0 16px;
    
        img {
            height: 96px;
            margin-top: 96px;
            margin-bottom: 32px;
        }
        video {
            height: auto;
            width: 100%;
            margin-top: 32px
        }
        .about-content {
            @include layout( mobile );
            max-width: 100%;
            padding-bottom: 16px;
            margin: 0;
            gap: 16px;
    
            article {
                width: auto;
    
                h2 {
                    padding: 0 16px;
                    margin: 0;
                    margin-top: -16px;
                    margin-bottom: 0px;
                }
                span {
                    font-size: 64px;
                    margin: 0;
                }
                p {
                    padding: 0 16px 16px 16px;
                    margin: 0;
                }
            }
            .content-1 {
                grid-column: 1 / 5;
                grid-row: 1;
            }
            .content-2 {
                grid-column: 1 / 5;
                grid-row: 1;
            }
            .content-3 {
                grid-column: 1 / 5;
                grid-row: 1;
            }
            .about-photo {
                background-color: green;
                border-radius: 8px;
                grid-row: auto;
            }
            .a1, .b1, .c1 {
                grid-column: 1 / 5;
            }
        }
    }
}