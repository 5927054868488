@mixin color( $text, $background, $border ) {
    color: $text;
    background-color: $background;
    border-color: $border;
}
@mixin flex( $grow, $shrink, $basis ) {
    display: flex;
    flex: $grow $shrink $basis;
}
@mixin flex-wrap( $wrap, $direction, $posX, $posY ) {
    display: flex;
    flex-wrap: $wrap;
    flex-direction: $direction;
    justify-content: $posX;
    align-items: $posY;
}

@mixin layout( $breakpoint ) {
    @if $breakpoint == mobile {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 0 16px;
    } @else if $breakpoint == desktop {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        padding: 0 80px;
        max-width: 1280px;
        width: 100%;
        gap: 24px;
    }
}

@mixin overlay( $width, $height ) {
    max-width: $width;
    height: $height;
    position: absolute;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 33%, rgba(0,0,0,0) 66%, rgba(0,0,0,1) 100%);
}