.about {
    @include flex-wrap( nowrap, column, center, center );
    width: 100%;

    h1 {
        display: none;
    }
    img {
        height: 128px;
        margin-top: 128px;
        margin-bottom: 32px;
        animation: contact-scale 2s ease-in-out forwards;
    }
    video {
        height: 312px;
        margin-top: 32px
    }
    .about-content {
        @include layout( desktop );
        margin-top: 24px;

        article {
            width: 100%;
            background-color: rgba(white, 0.1);
            border-radius: 8px;
            opacity: 0;

            h2 {
                padding: 0 16px;
                margin: 0;
                margin-top: -16px;
                margin-bottom: 8px;
            }
            span {
                font-size: 64px;
                margin: 0;
            }
            p {
                padding: 0 16px 16px 16px;
                margin: 0;
            }
        }
        .content-1 {
            grid-column: 2 / 8;
            animation: ani-opacity 2s 0.5s ease-in-out forwards;
        }
        .content-2 {
            grid-column: 6 /12;
            animation: ani-opacity 2s 0.7s ease-in-out forwards;
        }
        .content-3 {
            grid-column: 2 / 8;
            animation: ani-opacity 2s 0.9s ease-in-out forwards;
        }
        .about-photo {
            position: relative;
            border-radius: 8px;
            aspect-ratio: 3 / 2;
            grid-row: 1;
            opacity: 0;
            overflow: hidden;
            background-position: center;
            background-size: cover;

            img {
                position: absolute;
                margin-top: 0;
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
        .a1 {
            grid-column: 8 / 12;
            animation: ani-opacity 2s 0.9s ease-in-out forwards;
        }
        .b1 {
            grid-column: 2 / 6;
            animation: ani-opacity 2s 1.2s ease-in-out forwards;
        }
        .c1 {
            grid-column: 8 / 12;
            animation: ani-opacity 2s 1.5s ease-in-out forwards;
        }
    }
}