.home {
    @include layout( desktop );

    .img-wrap {
        @include flex-wrap( nowrap, row, center, start );
        width: 100%;
        height: 120vh;
        top: 0;
        left: 0;
        position: absolute;
        overflow: hidden;
        opacity: 0;
        animation: ani-opacity 3s 0.5s ease-in-out forwards;
        z-index: -1;

        img {
            margin-top: -5vh;
            max-height: 125vh;
            max-width: 100%;
            aspect-ratio: 3 / 2;
            z-index: -1;
        }
        .parallax-front {
            position: absolute;
            animation: parallax-front 3s 0.5s ease-out;

            .parallax-gif {
                position: absolute;
                z-index: 2;
            }
        }
        .parallax-back {
            z-index: -2;
            animation: parallax-back 3s 0.5s ease-out;

            .img-overlay {
                @include overlay( 100%, 125vh );
                aspect-ratio: 3 / 2;
            }
        }
        .mobile-img-overlay {
            display: none;
        }
    }
    article {
        @include flex-wrap( nowrap, column, center, center );
        grid-column: 1 / 5;
        margin-top: 10vh;
        height: 100%;
        z-index: 1;
        opacity: 0;
        background: radial-gradient(circle, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 70%, rgba(0,0,0,0) 100%);
        animation: ani-opacity 1.2s 2s ease-in-out forwards;

        h1 {
            font-size: 40px;
            line-height: 0.8;
            margin: 32px 0;
    
            span {
                font-size: 80px;
            }
        }
        p {
            margin-top: 0;
        }
        > div {
            @include flex-wrap( nowrap, row, start, center );
            width: 100%;
            gap: 16px;
        }
    }
}
// ANIMATION LOAD
@keyframes parallax-front {
    0% {
        transform: translateX( -3% );
    }
    100% {
        transform: translateX( 0% );
    }
}
@keyframes parallax-back {
    0% {
        transform: translateX( 3% );
    }
    100% {
        transform: translateX( 0% );
    }
}

@keyframes parallax-load {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}