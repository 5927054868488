@media only screen and ( max-width: 500px ) {
    .contact {
        @include layout( mobile );
    
        .img-wrap {
            @include flex-wrap( nowrap, row, center, start );
            width: 100%;
            height: 100vh;
            bottom: 320px;
            left: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
    
            img {
                top: 0;
                margin-left: 100px;
                max-height: 100vh;
                max-width: none;
                aspect-ratio: 3 / 2;
            }
            .img-back {
                opacity: 70%;
            }
            .img-overlay {
                display: flex;
                background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 100%);
                width: 100vw;
                height: 100vh;
                margin-top: 0;
                left: 0;
                position: absolute;
                z-index: 1;
            }
        }
        .content {
            grid-column: 1 / span 4;
            @include flex-wrap( nowrap, column, center, end );
            position: relative;
    
            > article {
                @include flex-wrap( nowrap, column, end, center );
                position: relative;
                margin-top: 80px;
                width: 100%;
    
                h1 {
                    opacity: 0;
                    margin: 0;
                    margin-bottom: 8px;
                    font-size: 42px;
                    animation: contact-scale 2s 1.5s ease-in-out forwards;
                }
                > div {
                    @include flex-wrap( nowrap, row, space-between, center );
                    gap: 7%;
                    margin-top: -16px;
                    opacity: 0;
                    animation: contact-scale 2s 1.7s ease-in-out forwards;
    
                    hr {
                        width: 8px;
                        height: 4px;
                        background: rgba(255, 255, 255, 0.5);
                        border-color: transparent;
                        border-radius: 8px;
                    }
                    h2 {
                        font-size: 14px;
                        width: fit-content;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.5);
                        line-height: 1.2;
                        font-weight: 500;
                    }
                }
            }
            > div {
                @include flex-wrap( nowrap, column, center, end );
                width: 64%;
                margin-top: 24px;
    
                .description {
                    @include flex-wrap( nowrap, column, center, end );
                    font-size: 14px;
                    margin-top: 4px;
                    color: rgba(220, 220, 220, 0.6);
                    opacity: 0;
                }
                article:nth-child(1) {
                    .button {
                        animation: contact-fade 4s ease-in-out forwards;
    
                        > p {
                            animation: contact-fade 4s ease-in-out;
                        }
                    }
                    .description {
                        animation: ani-opacity 1s 2.6s ease-in-out forwards;
                    }
                }
                article:nth-child(2) {
                    .button {
                        animation: contact-fade 4.2s ease-in-out forwards;
    
                        > p {
                            animation: contact-fade 4.2s ease-in-out;
                        }
                    }
                    .description {
                        animation: ani-opacity 1s 2.8s ease-in-out forwards;
                    }
                }
                article:nth-child(3) {
                    .button {
                        animation: contact-fade 4.4s ease-in-out forwards;
    
                        > p {
                            animation: contact-fade 4.4s ease-in-out;
                        }
                    }
                    .description {
                        animation: ani-opacity 1s 3s ease-in-out forwards;
                    }
                }
            }
        }
    }
}