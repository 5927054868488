body {
    background: black;
    margin: 0;
}

main {
    @include flex-wrap( nowrap, row, center, start );
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 293px;
}

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background-color: black;
}
::-webkit-scrollbar-thumb {
    background: linear-gradient( to bottom, black, rgb(20, 20, 20) );
    background-size: 100% 100%;
    background-position: 100% 100%;
    border-radius: 0 0 32px 32px;
    transition: 0.2s ease-in-out;
}

@media only screen and ( max-width: 500px ) {
    main {
        min-height: 100vh;
        padding-bottom: 270px;
    }
}