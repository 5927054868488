nav {
    .nav-text {
        @include flex-wrap( nowrap, row, center, center );
        position: relative;
        padding: 0 10px;
        height: 24px;
        width: fit-content;
        transition: 0.2s ease-in-out;
    
        p {
            @include flex-wrap( nowrap, row, center, center );
            font-family: 'StripesNation';
            font-size: 24px;
            padding-left: 1px;
            padding-top: 3px;
            height: 36px;
        }
        .link-bgr-wrap, .link-bgl-wrap {
            position: absolute;
            overflow: hidden;
            width: 16px;
            height: 16px;
        }
        .link-bgr-wrap {
            top: 0px;
            right: -1px;
    
            > div {
                top: 0;
                right: 3px;
            }
        }
        .link-bgl-wrap {
            bottom: -4px;
            left: -1px;
    
            > div {
                bottom: 0;
                left: 3px;
            }
        }
        .link-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 3.5px solid white;
            transition: 0.2s ease-in-out;
            transform: skew(-15deg);
            z-index: -1;
            scale: 0;
        }
        .link-active {
            position: absolute;
            top: 2px;
            width: 50%;
            height: 100%;
            border-bottom: 3px solid red;
            transition: 0.2s ease-in-out;
            transform: skew(-15deg);
            z-index: -2;
            scale: 0 1;
        }
        &:hover {
            scale: 0.92;
    
            .link-bg {
                scale: 1;
            }
        }
    }
}